<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Data Unit Utama</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" justify="end">
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="my-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="
                visible = true;
                isEdit = false;
              "
              >Buat Unit Utama<v-icon class="ml-2" color="primary"
                >add_circle</v-icon
              ></v-btn
            >
            <v-spacer />
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="visibleImport = true"
              >Import<v-icon class="ml-2" color="primary"
                >file_download</v-icon
              ></v-btn
            >
            <v-btn
              text
              class="text-capitalize caption headline-color"
              color="primary"
              @click="exportUnit"
              :loading="loadingExport"
              >Export<v-icon class="ml-2" color="primary"
                >file_upload</v-icon
              ></v-btn
            >
          </v-card-actions>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="units"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small color="primary" @click="handleEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="error" @click="handleDelete(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ isEdit ? "Ubah" : "Buat" }} Unit Utama
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.unit_utama_id"
                :disabled="isEdit"
                outlined
                dense
                :rules="[v => !!v || 'Kode Unit Utama harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.nama_unit_utama"
                outlined
                dense
                :rules="[v => !!v || 'Nama Unit Utama harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Singkatan</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.singkatan"
                outlined
                dense
                :rules="[v => !!v || 'Singkatan harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Aktif</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-switch v-model="form.unit_aktif"></v-switch>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleImport" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visibleImport = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Import Unit Utama
        </v-card-text>
        <v-divider class="mb-4" />
        <FileDraggable
          ref="fileDraggable"
          :accept="this.accept"
          @on-change="handleOnChange"
        />
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visibleImport = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="loadingImport"
            @click="importUnit"
            >Import</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ACCEPT_IMPORT } from "@/common/constant";
import DataUtamaService from "@/services/resources/data.utama.service";
const FileDraggable = () => import("@/components/File/Draggable");

export default {
  components: {
    FileDraggable
  },
  data() {
    return {
      accept: [ACCEPT_IMPORT.EXCEL],
      visible: false,
      visibleImport: false,
      valid: false,
      isEdit: false,
      loading: false,
      loadingImport: false,
      loadingExport: false,
      formLoading: false,
      search: null,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false
        },
        {
          text: "Kode",
          value: "unit_utama_id",
          sortable: false
        },
        {
          text: "Unit Utama",
          value: "nama_unit_utama",
          sortable: false
        },
        {
          text: "Singkatan",
          value: "singkatan",
          sortable: false
        },
        {
          text: "Aktif",
          value: "unit_aktif",
          sortable: false
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      units: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["unit_utama_id"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      fileImport: null,
      form: {
        unit_utama_id: null,
        nama_unit_utama: null,
        singkatan: null,
        unit_aktif: false
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getUnitList);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getUnitList);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
    visibleImport(val) {
      if (!val) {
        this.fileImport = null;
        this.$refs.fileDraggable.removeFile();
        this.$refs.fileDraggable.closeAlert();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailUnit(item.unit_utama_id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              unit_utama_id: item.unit_utama_id
            };
            this.deleteUnit(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saveUnit({
          ...this.form,
          action: this.isEdit ? 2 : 1,
          unit_aktif: this.form.unit_aktif ? "Y" : "N"
        });
      }
    },
    handleOnChange(val) {
      this.fileImport = val;
    },
    // Service
    async getUnitList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getUnitUtamaList({
          filter: {
            search: this.search
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let units = list;
              units.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.units = units;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveUnit(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveUnitUtama(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getUnitList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailUnit(id) {
      try {
        this.loading = true;
        await DataUtamaService.getUnitUtamaDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$_.merge(this.form, {
                ...data,
                unit_aktif: data.unit_aktif == "Y"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteUnit(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteUnitUtama(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getUnitList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportUnit() {
      try {
        this.loadingExport = true;
        await DataUtamaService.exportUnitUtama()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.downloadTemplate(
                data,
                `export_unit_utama_${this.$moment(new Date()).format(
                  "YYYY-MM-DD"
                )}.xlsx`
              );
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    },
    async importUnit() {
      try {
        this.loadingImport = true;
        let formData = new FormData();
        formData.append("file", this.fileImport);
        await DataUtamaService.importUnitUtama(formData)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingImport = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
